export const initLoginFormCredentials = {
  email: "",
  password: "",
};
export const initLoginFormErrors = {
  emailError: "",
  passwordError: "",
  loginError: false,
};

export const initSignUpForm = {
  name: "",
  email: "",
  password: "",
  contactNumber: "",
  role: "",
};
export const initSignUpErrorForm = {
  nameError: "",
  emailError: "",
  passwordError: "",
  contactNumberError: "",
  roleError: "",
  signUpError: false,
};

export const initProfileCompleteForm = {
  name: "",
  email: "",
  contactNumber: "",
  role: "",
};
export const initProfileCompleteErrorForm = {
  nameError: "",
  emailError: "",
  contactNumberError: "",
  roleError: "",
  profileCompleteError: "",
};


//New designs
export const initCreateAccountForm = {
  email: "",
  password: "",
  confirmPassword: "",
}

export const initCreateAccountErrorForm = {
  emailError: "",
  passwordError: "",
  confirmPasswordError: "",
  createAccountError: false,
}

export const initRegistrationForm = {
  fullName: "",
  phoneNumber: "",
  postalCode: "",
  city: "",
  profilePictureUrl: ""
}

export const initRegistrationErrorForm = {
  fullNameError: "",
  phoneNumberError: "",
  postalCodeError: "",
  cityError: "",
}

// export const initCenterAdminForm = {
//   adminName: "",
//   adminPhoneNumber: "",
//   adminProfilePictureUrl: ""
// }

export const initCenterRegistrationForm = {
  adminName: "",
  adminPhoneNumber: "",
  adminProfilePictureUrl: "",
  centerName: "",
  centerPhoneNumber: "",
  imageUrls: [],
  coordinates: [0],
  centerAddr: "",
  city: "",
  state: "",
  postalCode: "",
  webUrl: "",
  rating: 0,
  placeId: "",
  email: "",
  password: "",
  confirmPassword: ""
}

export const initCenterRegistrationErrorForm = {
  adminNameError: "",
  adminPhoneNumberError: "",
  centerNameError: "",
  centerPhoneNumberError: "",
  imageUrlsError: "",
  coordinatesError: "",
  addressError: "",
  cityError: "",
  stateError: "",
  postalCodeError: "",
  webUrlError: "",
  ratingError: "",
  placeIdError: "",
  emailError:"",
  passwordError: "",
  confirmPasswordError: "",
}

export const initNewPasswordForm = {
  newPassword: "",
  confirmPassword: ""
}
export const initNewPasswordFormErrors = {
  newPasswordError: "",
  confirmPasswordError: "",
}