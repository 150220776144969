import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LoaderSpinner from "./components/Loader/Loader";
import { QUERY_PARAMS_KEY, STORAGE_KEYS } from "./constants/keys";
import { setAuthUser } from "./features/Auth Slice/authSlice";
import { setLabels } from "./features/Labels Slice/labelsSlice";
import "./global.css";
import CompleteCenterRegistration from "./Pages/completeCenterRegistration/CompleteCenterRegistration";
import CompleteRegistration from "./Pages/completeRegistration/CompleteRegistration";
import CreateAccount from "./Pages/createAccount/CreateAccount";
import EmailSentScreen from "./Pages/emailSentScreen/EmailSentScreen";
import ForgotPasswordScreen from "./Pages/forgotPasswordScreen/ForgotPasswordScreen";
import LoaderPage from "./Pages/LoaderPage";
import Login from "./Pages/Login/Login";
import NewPasswordScreen from "./Pages/newPasswordScreen/NewPasswordScreen";
import RegisterOption from "./Pages/RegistrationScreens/RegisterOption/RegisterOption";
import VerificationEmail from "./Pages/verificationEmail/VerificationEmail";
import VerifyingEmail from "./Pages/verifyingEmail/VerifyingEmail";
import { fetchLabels, refreshToken, signOut } from "./services/auth.service";
import { store } from "./store/store";
import spinnerSvc from "./utils/spinner-service";
import { checkForTokensExpiry } from "./utils/tokensExp";
function App() {
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI =
    urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI) ||
    urlParams.get("redirect_uri");
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const isEditing = JSON.parse(urlParams.get(QUERY_PARAMS_KEY.EDIT) as string) || undefined
  const isRfToken = localStorage.getItem(STORAGE_KEYS.RF_TOKEN)
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const rememberMe = localStorage.getItem(STORAGE_KEYS.REMEMBER_ME);
  const paramToken = urlParams.get("token");

  const checkForTokens = async () => {
    //sign out on refresh so acc token removes
    const res = await checkForTokensExpiry(redirectURI as string);
    console.log("check: ", res);
    if (res?.accToken) {

      if (isRfToken && !isEditing) {
        navigate(
          `/select-account${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
          }`
        );
        return;
      }

    } else if (res?.rfToken) {

      const res = await refreshToken();
      if (res && !isEditing) {
        navigate(
          `/select-account${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
          }`
        );
      }
      return;

    } else {
      signOut();
      navigate(
        `/sign-in${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
        }${isEditing ? "&edit=" + isEditing : ""}`
      );
    }
  };

  const getLabels = async () => {
    const res: any = await fetchLabels();
    if (res) {
      store?.dispatch(setLabels(res?.labels));
    }
  };

  useEffect(() => {
    if (!rememberMe && !isEditing && !paramToken) signOut()
    if (isEditing)
      localStorage.setItem(STORAGE_KEYS.EDIT, JSON.stringify(isEditing));
    if (!role && !paramToken) checkForTokens();
    getLabels();
  }, []);

  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((data: any) => {
      setLoading(data);
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const userDetails = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.USER) as string
  );

  useEffect(() => {
    // if (!userDetails?.id || userDetails?.role !== "admin") return;
    store.dispatch(setAuthUser(userDetails));
  }, [userDetails]);

  return (
    <>
      {loading && <LoaderSpinner />}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        theme="colored"
      // transition= Bounce
      />
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      >
        <div className="App">
          <Routes>
            <Route path="/sign-in" element={<Login />} />
            <Route path="/register-option" element={<RegisterOption />} />
            <Route path="/select-account" element={<LoaderPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route path="/email-sent" element={<EmailSentScreen />} />
            <Route path="/new-password" element={<NewPasswordScreen />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/verification-email" element={<VerificationEmail />} />
            <Route path="/verifying-email" element={<VerifyingEmail />} />
            <Route path="/complete-registration" element={<CompleteRegistration />} />
            <Route path="/edit-user-profile" element={<CompleteRegistration />} />
            <Route path="/complete-center-registration" element={<CompleteCenterRegistration />} />
            <Route path="/edit-center-profile" element={<CompleteCenterRegistration />} />
            {/* <Route path="/edit-center-profile" element={<EditCenterProfile />} /> */}
            <Route path="*" element={<Navigate to={"/sign-in"} />} />

            {/* <Route path="/parent-sign-up" element={<ParentSignUp />} />  */}
            {/* <Route path="/complete-profile" element={<ProfileDataForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            {/* <Route path="/" element={<Navigate to={"/sign-in"} />} /> */}
            {/* <Route path="/select-account*" element={<Navigate to={"/sign-in"} />} /> */}
          </Routes>
        </div>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
