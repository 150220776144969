import React, { useEffect, useState } from 'react'
import { QUERY_PARAMS_KEY, STORAGE_KEYS } from '../../constants/keys';
import { verifyEmail } from '../../services/auth.service';
import { jwtDecode } from 'jwt-decode';
import { USER_ROLES } from '../../constants/roles';

const VerifyingEmail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get("redirect_uri");
  const paramToken = urlParams.get("token");
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);

  const role = localStorage.getItem(STORAGE_KEYS.ROLE)

  const verifyUserEmail = async (token: string) => {
    const res = await verifyEmail(token);
    if (res?.ok) {
      if (role === USER_ROLES.CENTER)
        window.location.href = `/complete-center-registration${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""}${paramToken ? "&token=" + paramToken : ""}`;
      else
        window.location.href = `/complete-registration${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""}${paramToken ? "&token=" + paramToken : ""}`;
    }
  }

  useEffect(() => {
    if (!paramToken) return;
    verifyUserEmail(paramToken)
  }, [paramToken]);

  return (
    <div></div>
  )
}

export default VerifyingEmail