import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaApple } from "react-icons/fa";
import { LuEye, LuEyeOff } from "react-icons/lu";
import {
  initLoginFormCredentials,
  initLoginFormErrors,
} from "../../constants/initialForms";
import { regexPatterns } from "../../constants/regexPatterns";
import { signIn } from "../../services/auth.service";
import { ERR_MSGS } from "../../constants/errorMsgs";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import RequiredAsterisk from "../ui/icons/RequiredAsterisk";
import { fbAppId } from "../../constants/environment.constants";
import { QUERY_PARAMS_KEY } from "../../constants/keys";

const LoginForm = (props: any) => {
  const [loginForm, setLoginForm] = useState(initLoginFormCredentials);
  const [loginFormErrors, setLoginFormErrors] = useState(initLoginFormErrors);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI);
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const isEditing = JSON.parse(urlParams.get(QUERY_PARAMS_KEY.EDIT) as string);
  const navigate = useNavigate();

  const handleRememberMeChange = () => {
    setRememberMe((prev) => !prev);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setLoginForm((prev) => ({ ...prev, [name]: value }));
    setLoginFormErrors((prev) => ({ ...prev, loginError: false }));
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const key =
      event?.target?.name === "email" ? "emailError" : "passwordError";
    setLoginFormErrors((prev) => ({ ...prev, [key]: "", loginError: false }));
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const key =
      event?.target?.name === "email" ? "emailError" : "passwordError";
    const errorMsg =
      name === "email" ? ERR_MSGS?.Invalid_Email : ERR_MSGS?.Invalid_Password;
    const value = event?.target?.value;
    const regex =
      name === "email" ? regexPatterns?.email : regexPatterns?.password;
    if (!value?.match(regex)) {
      setLoginFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSignIn = async () => {
    const res = await signIn(
      redirectURI as string,
      clientID as string,
      loginForm,
      rememberMe
    );
    if (res?.ok && isEditing) {
      navigate(
        `/complete-registration?${"client_id=" + clientID}${"&redirect_uri=" + redirectURI //the redirect_uri is small here
        }&edit=${true}`
      );
      clearForm();
      return;
    }
    if (res?.ok) {
      window.location.replace(
        `${redirectURI}${"?authCode=" + res?.data?.authCode}&${"remember_me=" + rememberMe
        }`
      );
      clearForm();
    } else setLoginFormErrors((prev) => ({ ...prev, loginError: true }));
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (loginFormErrors?.emailError || loginFormErrors?.passwordError) {
      return;
    } else handleSignIn();
  };

  const clearForm = () => {
    setLoginForm(initLoginFormCredentials);
    setLoginFormErrors(initLoginFormErrors);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      onSocialLogin("google", credentialResponse?.access_token as string);
      console.log("credentialResponse", credentialResponse);
    },
    onError: () => {
      toast.error("Couldn't Sign In with Google. Try again later");
      console.log("Login Failed");
    },
    flow: "implicit",
  });

  const onSocialLogin = async (provider: string, token: string) => {
    const reqBody = { provider, providerToken: token };
    const res = await signIn(
      redirectURI as string,
      clientID as string,
      reqBody,
      rememberMe
    );
    if (res?.ok) {
      window.location.replace(
        `${redirectURI}?${"authCode=" + res?.data?.authCode}${"&remember_me=" + rememberMe
        }`
      );
    } else if (res?.token) {
      navigate(
        `/complete-profile?${clientID ? "client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
        }`,
        { state: { socialAccToken: res?.token } }
      );
    } else {
      return;
    }
  };

  const handleNavToSignUp = () => {
    return `/register-option?${clientID ? "client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`;
  };

  const handleNavToForgotPassword = () => {
    return `/forgot-password?${clientID ? "client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`;
  };

  return (
    <>
      <div className="flex justify-center items-center flex-col h-screen p-3">
        <div className="">
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-5 md:px-16 rounded-[20px] max-w-[448px]">
            <h3 className="text-secondary text-2xl font-semibold mb-4">
              Welcome back
            </h3>
            <form onSubmit={onSubmit}>
              <div className="mb-6">
                <label className="text-base text-secondaryVariant">
                  Email
                  <RequiredAsterisk />
                </label>
                <input
                  name="email"
                  type="email"
                  placeholder="Enter Your Email"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white mt-2"
                  value={loginForm?.email}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {loginFormErrors?.emailError && (
                  <p className="text-red-600 pl-5">
                    {loginFormErrors?.emailError}
                  </p>
                )}
              </div>
              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <label className="text-base text-secondaryVariant">
                    Password
                    <RequiredAsterisk />
                  </label>
                  <Link
                    to={handleNavToForgotPassword()}
                    className="text-sm text-statusColor"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <div className="relative">
                  <input
                    name="password"
                    type={`${showPassword ? "text" : "password"}`}
                    placeholder="Enter Your Password"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white"
                    value={loginForm?.password}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <button
                    className="absolute right-2 top-[17px]"
                    type="button"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? (
                      <LuEyeOff className="w-4 h-5 text-secondary" />
                    ) : (
                      <LuEye className="w-4 h-5 text-secondary" />
                    )}
                  </button>
                </div>
                {loginFormErrors?.passwordError && (
                  <p className="text-red-600 pl-5">
                    {loginFormErrors?.passwordError}
                  </p>
                )}
              </div>
              {loginFormErrors?.loginError && (
                <p className="text-red-600 text-center ">
                  {ERR_MSGS.Login_Error}
                </p>
              )}
              <div className="flex items-center gap-1 mb-7">
                <label
                  className="relative flex items-center p-3 rounded-full cursor-pointer"
                  htmlFor="check"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-secondary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-5 before:w-5 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity checked:border-secondary checked:bg-secondary"
                    id="check"
                    onChange={handleRememberMeChange}
                  />
                  <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label className="text-base text-secondaryVariant">
                  Save password
                </label>
              </div>
              <button className="btnPrimary" type="submit">
                Login
              </button>
              <p className="flex items-center justify-center gap-3 text-base text-secondaryVariant text-opacity-50 mt-6">
                Don’t have an account?{" "}
                <Link
                  to={handleNavToSignUp()}
                  className=" text-secondary font-medium"
                >
                  Sign up
                </Link>
              </p>
            </form>
          </div>
          <div className="flex items-center px-5 md:px-16 gap-3 flex-col">
            <p className="text-sm text-secondary text-opacity-50 mt-3">OR</p>
            <button
              className="flex items-center gap-3 text-base text-secondary border border-secondaryVariant rounded-lg w-full py-2.5 px-3 hover:shadow-btnShadow"
              type="button"
              onClick={() => googleLogin()}
            >
              <FcGoogle className="w-[26px] h-[26px]" />
              Continue with Google
            </button>
            <FacebookLogin
              appId={fbAppId}
              onSuccess={(response) => {
                onSocialLogin("facebook", response?.accessToken);
              }}
              onFail={(err) => {
                if (err?.status === "loginCancelled") {
                  return;
                }
                toast.error("Couldn't Sign In with Facebook. Try again later");
              }}
              render={(props) => (
                <button
                  className="flex items-center gap-3 text-base text-secondary border border-secondaryVariant rounded-lg w-full py-2.5 px-3 hover:shadow-btnShadow"
                  type="button"
                  onClick={props.onClick}
                >
                  <BiLogoFacebookCircle className="w-[26px] h-[26px] text-[#0866ff]" />
                  Continue with Facebook
                </button>
              )}
            />
            {/* <button className="flex items-center gap-3 text-base text-secondary border border-secondaryVariant rounded-lg w-full py-2.5 px-3 hover:shadow-btnShadow">
              <FaApple className="w-[26px] h-[26px] text-black" />
              Continue with Apple
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
